import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header2'
import Map from '../components/Map'

function Mapa() {
  return (
    <>
    <Header />
    <Map />
    <Footer />
    </>
  )
}

export default Mapa