import React from 'react'
import Header from '../components/Header2';
import Footer from '../components/Footer';

function Items () {
 
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}

export default Items;